import { DataSet } from 'vis-data/peer/esm/vis-data'
import { messageTypes } from '../../../../actions/messages'
import variables from '../../../../assets/scss/utilities/variables.module.scss'
import settings from '../../../../config/'

const defaultImageSize = 47
const imgDirGreen = '/images/icons/green/'
const imgDirGray = '/images/icons/gray/'
const imgDirDesert = '/images/icons/desert/'
const imgDirYellow = '/images/icons/yellow/'
const imgDirKhaki = '/images/icons/khaki/'
const imgDir = '/images/icons/'

const nodeLabelColor1 = variables?.map_label_1_color
const nodeLabelColor2 = variables?.map_label_2_color
const networkCornerColor = variables?.map_network_line_color
const edgeColor = variables?.map_edge_color
const networkHeaderSubtitleColor = variables?.map_network_header_subtitle_color
const networkHeaderSubtitle2Color = variables?.map_network_header_subtitle2_color
const nodeLabelRedColor = variables?.map_label_red_color
const nodeLabelGray = variables?.map_label_gray_color
const nodeLabelYellow = variables?.map_label_yellow_color
const hoverColor = variables?.map_hover_color

export const options = {
  interaction: {
    hover: true,
    dragNodes: false
  },
  layout: {
    randomSeed: 0
  },
  physics: false,

  nodes: {
    font: {
      color: nodeLabelGray,
      multi: 'markdown',
      size: settings.map_default_font_size || 14
    },
    labelHighlightBold: false,
    chosen: {
      node: function (values) {
        values.shadow = true
        values.shadowColor = hoverColor
        values.shadowSize = 10
        values.shadowX = 0
        values.shadowY = 0
      }
    }
  },
  edges: {
    width: 2,
    hoverWidth: 0,
    labelHighlightBold: false,
    selectionWidth: 0,
    color: {
      color: edgeColor,
      highlight: edgeColor,
      hover: edgeColor
    }
  },
  groups: {
    // nodes visualisation groups
    network_config_point: {
      shape: 'icon',
      icon: {
        code: '\uf013',
        color: 'black',
        size: 40
      },
      heightConstraint: { minimum: 10 },
      widthConstraint: { minimum: 10 },
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      }
    },
    network_edge_point_config: {
      label: '',
      heightConstraint: { minimum: 10 },
      widthConstraint: { minimum: 10 },
      color: {
        border: networkCornerColor,
        background: networkCornerColor,
        highlight: { border: networkCornerColor, background: networkCornerColor },
        hover: { border: networkCornerColor, background: networkCornerColor }
      },
      chosen: { node: {} }
    },
    network_edge_point: {
      shape: 'box',
      label: ' ',
      heightConstraint: { minimum: 10 },
      widthConstraint: { minimum: 10 },
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      chosen: { node: {} }
    },
    network_edge_point_hidden: {
      label: '',
      heightConstraint: { minimum: 10 },
      widthConstraint: { minimum: 10 },
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      }
    },

    // vm statuses
    node_status_green: {
      shape: 'image',
      image: imgDir + 'on.svg',
      size: 7
    },
    node_status_red: {
      shape: 'image',
      image: imgDir + 'off.svg',
      size: 7
    },
    node_status_yellow: {
      shape: 'image',
      image: imgDir + 'processing.svg',
      size: 7
    },

    node_label_green: {
      shape: 'box',
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      labelHighlightBold: false,
      font: {
        color: nodeLabelColor1
      }
    },
    node_label_red: {
      shape: 'box',
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      labelHighlightBold: false,
      font: {
        color: nodeLabelRedColor
      }
    },
    node_label_orange: {
      shape: 'box',
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      labelHighlightBold: false,
      font: {
        color: '#EE8C33'
      }
    },
    node_label_gray: {
      shape: 'box',
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      labelHighlightBold: false,
      font: {
        color: nodeLabelColor2
      }
    },
    node_label_yellow: {
      shape: 'box',
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      labelHighlightBold: false,
      font: {
        color: nodeLabelYellow
      }
    },

    network_header_subtitle: {
      shape: 'box',
      shapeProperties: {
        borderRadius: 0
      },
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      labelHighlightBold: false,
      font: {
        color: networkHeaderSubtitleColor
      },
      value: 1
    },
    network_header_subtitle2: {
      shape: 'box',
      shapeProperties: {
        borderRadius: 0
      },
      color: {
        border: 'transparent',
        background: 'transparent',
        highlight: { border: 'transparent', background: 'transparent' },
        hover: { border: 'transparent', background: 'transparent' }
      },
      labelHighlightBold: false,
      font: {
        color: networkHeaderSubtitle2Color
      },
      value: 1
    },
    // stars for challenge levels
    stars1: {
      shape: 'image',
      image: '/images/icons/1stars.svg',
      size: 33
    },
    stars2: {
      shape: 'image',
      image: '/images/icons/2stars.svg',
      size: 33
    },
    stars3: {
      shape: 'image',
      image: '/images/icons/3stars.svg',
      size: 33
    },
    check: {
      shape: 'image',
      image: '/images/icons/check.svg',
      size: 10
    },
    scenario: {
      shape: 'image',
      image: '/images/icons/scenario.svg',
      size: 33
    }
  }
}

const defaultGroups = {
  firewall_gray: {
    shape: 'image',
    image: imgDirGray + 'firewall_ikonka.svg',
    size: 32
  },
  firewall_yellow: {
    shape: 'image',
    image: imgDirYellow + 'firewall_ikonka.svg',
    size: 28
  },
  firewall_green: {
    shape: 'image',
    image: imgDirGreen + 'firewall_ikonka.svg',
    size: 28
  },
  wifi_gray: {
    shape: 'image',
    image: imgDirGray + 'wifi_ikonka.svg',
    size: 30
  },
  dns: {
    shape: 'image',
    image: imgDirGray + 'dns_ikonka.svg',
    size: 28
  },
  exchange: {
    shape: 'image',
    image: imgDirGreen + 'exchange_server_ikonka.svg',
    size: 32
  },
  domain_controller: {
    shape: 'image',
    image: imgDirGreen + 'domaincontroller_ikonka.svg',
    size: 32
  },
  domain_controller_green: {
    shape: 'image',
    image: imgDirGreen + 'domaincontroller_ikonka.svg',
    size: 32
  },
  domain_controller_khaki: {
    shape: 'image',
    image: imgDirKhaki + 'domaincontroller_ikonka.svg',
    size: 40
  },
  domain_controller_desert: {
    shape: 'image',
    image: imgDirDesert + 'domaincontroller_ikonka.svg',
    size: 40
  },
  vpn: {
    shape: 'image',
    image: imgDirYellow + 'vpn_ikonka.svg',
    size: 32
  },
  hidden: {
    shape: 'image',
    image: imgDirGray + 'hidden.svg',
    size: 29
  },
  server_windows: {
    shape: 'image',
    image: imgDirGreen + 'windows_server_ikonka.svg',
    size: 32
  },
  server_linux: {
    shape: 'image',
    image: imgDirGreen + 'linux_server_ikonka.svg',
    size: 30
    // chosen: {
    //   node: function(values, id, selected, hovering) {
    //         values.shadow = true
    //         values.shadowColor = '#ff0000'
    //         values.shadowX = 0
    //         values.shadowY = 0
    //         values.shadowSize = 10
    //       }
    //   }
  },
  server_linux_green: {
    shape: 'image',
    image: imgDirGreen + 'linux_server_ikonka.svg',
    size: 30
  },
  server_linux_gray: {
    shape: 'image',
    image: imgDirGray + 'linux_server_ikonka.svg',
    size: defaultImageSize
  },
  server_linux_khaki: {
    shape: 'image',
    image: imgDirKhaki + 'linux_server_ikonka.svg',
    size: 30
  },
  server_linux_desert: {
    shape: 'image',
    image: imgDirDesert + 'linux_server_ikonka.svg',
    size: 30
  },
  linux_victim: {
    shape: 'image',
    image: imgDirGreen + 'linux_victim_ikonka.svg',
    size: 33
  },
  linux_victim_green: {
    shape: 'image',
    image: imgDirGreen + 'linux_victim_ikonka.svg',
    size: defaultImageSize
  },
  linux_victim_gray: {
    shape: 'image',
    image: imgDirGray + 'linux_victim_ikonka.svg',
    size: defaultImageSize
  },
  linux_victim_khaki: {
    shape: 'image',
    image: imgDirKhaki + 'linux_victim_ikonka.svg',
    size: 33
  },
  linux_victim_desert: {
    shape: 'image',
    image: imgDirDesert + 'linux_victim_ikonka.svg',
    size: 33
  },
  macos: {
    shape: 'image',
    image: imgDirGreen + 'macos_ikonka.svg',
    size: defaultImageSize
  },
  server_linux_db: {
    shape: 'image',
    image: imgDirGreen + 'linux_server_db_ikonka.svg',
    size: defaultImageSize
  },
  server_linux_router: {
    shape: 'image',
    image: imgDirGreen + 'linux_server_router_ikonka.svg',
    size: defaultImageSize
  },
  server_gray: {
    shape: 'image',
    image: imgDirGray + 'server_ikonka.svg',
    size: defaultImageSize
  },
  server_hd_gray: {
    shape: 'image',
    image: imgDirGray + 'server_hd_ikonka.svg',
    size: 33
  },
  internet_gray: {
    shape: 'image',
    image: imgDirGray + 'publicNetwork_ikonka.svg',
    size: 34
  },
  internet_yellow: {
    shape: 'image',
    image: imgDirYellow + 'publicNetwork_ikonka.svg',
    size: 33
  },
  internet_green: {
    shape: 'image',
    image: imgDirGreen + 'publicNetwork_ikonka.svg',
    size: 33
  },
  pc_gray: {
    shape: 'image',
    image: imgDirGray + 'pc_ikonka.svg',
    size: defaultImageSize
  },
  pc_hd_gray: {
    shape: 'image',
    image: imgDirGray + 'pc_hd_ikonka.svg',
    size: 36
  },
  pc_windows: {
    shape: 'image',
    image: imgDirGreen + 'windows_ikonka.svg',
    size: 33
  },
  pc_windows_green: {
    shape: 'image',
    image: imgDirGreen + 'windows_ikonka.svg',
    size: defaultImageSize
  },
  pc_windows_khaki: {
    shape: 'image',
    image: imgDirKhaki + 'windows_ikonka.svg',
    size: 34
  },
  pc_windows_desert: {
    shape: 'image',
    image: imgDirDesert + 'windows_ikonka.svg',
    size: 34
  },
  pc_windows7_gray: {
    shape: 'image',
    image: imgDirGray + 'windows7_ikonka.svg',
    size: defaultImageSize
  },
  pc_windows_victim: {
    shape: 'image',
    image: imgDirGreen + 'windows_victim_ikonka.svg',
    size: defaultImageSize
  },
  pc_windows_victim_green: {
    shape: 'image',
    image: imgDirGreen + 'windows_victim_ikonka.svg',
    size: defaultImageSize
  },
  pc_windows_victim_khaki: {
    shape: 'image',
    image: imgDirKhaki + 'windows_victim_ikonka.svg',
    size: 35
  },
  pc_windows_victim_desert: {
    shape: 'image',
    image: imgDirDesert + 'windows_victim_ikonka.svg',
    size: 35
  },
  pc_windows_wifi_victim: {
    shape: 'image',
    image: imgDirGreen + 'windows_victim_wifi_ikonka.svg',
    size: defaultImageSize
  },
  pc_attacker: {
    shape: 'image',
    image: imgDirGreen + 'kali_ikonka.svg',
    size: defaultImageSize
  },
  hacker: {
    shape: 'image',
    image: imgDirGreen + 'hacker_ikonka.svg',
    size: 32
  },
  hacker_green: {
    shape: 'image',
    image: imgDirGreen + 'hacker_ikonka.svg',
    size: 32
  },
  hacker_gray: {
    shape: 'image',
    image: imgDirGray + 'hacker_ikonka.svg',
    size: 32
  },
  hacker_khaki: {
    shape: 'image',
    image: imgDirKhaki + 'hacker_ikonka.svg',
    size: 32
  },
  hacker_desert: {
    shape: 'image',
    image: imgDirDesert + 'hacker_ikonka.svg',
    size: 32
  },
  pc_attacker_green: {
    shape: 'image',
    image: imgDirGreen + 'kali_ikonka.svg',
    size: defaultImageSize
  },
  pc_attacker_gray: {
    shape: 'image',
    image: imgDirGray + 'kali_ikonka.svg',
    size: 45
  },
  pc_attacker_yellow: {
    shape: 'image',
    image: imgDirYellow + 'kali_ikonka.svg',
    size: defaultImageSize
  },
  printer: {
    shape: 'image',
    image: imgDirGreen + 'drukarka_ikonka.svg',
    size: 32
  },
  printer_green: {
    shape: 'image',
    image: imgDirGreen + 'drukarka_ikonka.svg',
    size: 32
  },
  printer_khaki: {
    shape: 'image',
    image: imgDirKhaki + 'drukarka_ikonka.svg',
    size: 32
  },
  printer_desert: {
    shape: 'image',
    image: imgDirDesert + 'drukarka_ikonka.svg',
    size: 32
  },
  pc_wifi_attacker: {
    shape: 'image',
    image: imgDirGreen + 'kali_wifi_ikonka.svg',
    size: defaultImageSize
  },
  ctf: {
    shape: 'image',
    image: imgDirGreen + 'ctf_ikonka.svg',
    size: 33
  },
  switch_gray: {
    shape: 'image',
    image: imgDirGray + 'switch_ikonka.svg',
    size: 32
  },
  bts_gray: {
    shape: 'image',
    image: imgDirGray + 'gsmbts_ikonka.svg',
    size: 34
  },
  acs_gray: {
    shape: 'image',
    image: imgDirGray + 'skd_ikonka.svg',
    size: 32
  },
  esxi_gray: {
    shape: 'image',
    image: imgDirGray + 'esxi_server_ikonka.svg',
    size: 33
  }
}

export const attributeGroups = settings.custom_attribute_set || {}
export const networkGroups = {}
export const networkColors = [networkCornerColor].concat(settings.custom_map_colors || [])

// prepare network and network header color groups
networkColors.forEach(color => {
  networkGroups['network_corner_horizontal_' + color] = {
    shape: 'box',
    label: '',
    heightConstraint: { minimum: 1 },
    borderWidth: 0,
    shadow: false,
    shapeProperties: {
      borderRadius: 0
    },
    margin: 0.5,
    color: {
      border: 'transparent',
      background: color,
      highlight: { border: 'transparent', background: color },
      hover: { border: 'transparent', background: color }
    },
    chosen: { node: {} }
  }

  networkGroups['network_corner_vertical_' + color] = {
    shape: 'box',
    label: '',
    widthConstraint: { minimum: 1 },
    borderWidth: 0,
    shadow: false,
    shapeProperties: {
      borderRadius: 0
    },
    margin: 0.5,
    color: {
      border: 'transparent',
      background: color,
      highlight: { border: 'transparent', background: color },
      hover: { border: 'transparent', background: color }
    },
    chosen: { node: {} }
  }

  networkGroups['network_header_title_' + color] = {
    shape: 'box',
    shapeProperties: {
      borderRadius: 0
    },
    color: {
      border: 'transparent',
      background: 'transparent',
      highlight: { border: 'transparent', background: 'transparent' },
      hover: { border: 'transparent', background: 'transparent' }
    },
    labelHighlightBold: false,
    font: {
      size: 18,
      color
    },
    value: 1
  }
})

options.groups = {
  ...settings.custom_icon_set,
  ...defaultGroups,
  ...options.groups,
  ...networkGroups
}

options.groups = {
  ...options.groups,
  ...attributeGroups
}

const nodeGroupsBlackListed = [
  'network_edge_point',
  'network_edge_point_config',
  'node_label_green',
  'node_label_red',
  'node_label_gray',
  'node_label_yellow',
  'network_header_title',
  'network_header_subtitle',
  'network_header_subtitle2',
  'network_config_point',
  'network_edge_point_hidden',
  'vpn',
  'node_status_green',
  'node_status_yellow',
  'node_status_red',
  'stars1',
  'stars2',
  'stars3'
]
  .concat(settings.custom_icon_set_groups_blacklist || [])
  .concat(Object.keys(networkGroups))
  .concat(Object.keys(attributeGroups))

export const groupsBlacklisted = Object.keys(options.groups)
  .filter(key => !nodeGroupsBlackListed.includes(key))
  .reduce((obj, key) => {
    obj[key] = options.groups[key]
    return obj
  }, {})

const setMapping = function (mappings, itemId, subitemId) {
  if (mappings[itemId] === undefined) {
    mappings[itemId] = []
  }

  mappings[itemId].push(subitemId)
}

const prepareVmStatus = function (item, status, nodes, language) {
  if (status === 'poweredOn') {
    nodes.add({
      id: item.id + '_status',
      group: 'node_status_green',
      x: item.x + 48,
      y: item.y - 30,
      title: messageTypes[language]['status_' + status]
    })
  } else if (['poweredOff', 'failed', 'suspended'].includes(status)) {
    nodes.add({
      id: item.id + '_status',
      group: 'node_status_red',
      x: item.x + 48,
      y: item.y - 30,
      title: messageTypes[language]['status_' + status]
    })
  } else {
    nodes.add({
      id: item.id + '_status',
      group: 'node_status_yellow',
      x: item.x + 48,
      y: item.y - 30,
      title: messageTypes[language]['status_' + status]
    })
  }
}

export const prepareNodes = function (nodesData, isClickableNode, language, editable, machines, stackType, icons) {
  const nodes = new DataSet([])
  const mappings = {}
  const done = (settings.ctf_custom_icons_done && settings.ctf_custom_icons_done[stackType]) || 'khaki'
  const undone = (settings.ctf_custom_icons_undone && settings.ctf_custom_icons_undone[stackType]) || 'desert'

  nodesData.forEach((item, i) => {
    if (item._deleted) return

    if (item.type === 'network_header') {
      // if item is network header, set needed separate nodes
      let yOffset = item.y
      nodes.add({
        id: item.id,
        x: item.x,
        y: yOffset,
        label: item['title_' + language] || item.title,
        group: (item.color && 'network_header_title_' + item.color) || 'network_header_title_' + networkCornerColor
      })
      yOffset += 25
      if (item.subtitle || item['subtitle_' + language]) {
        nodes.add({
          id: item.id + '_subtitle',
          x: item.x,
          y: yOffset,
          label: item['subtitle_' + language] || item.subtitle,
          group: 'network_header_subtitle'
        })
        yOffset += 25
        editable && setMapping(mappings, item.id, item.id + '_subtitle')
      }
      // network header may have additional subtitle
      if (item.subtitle2) {
        nodes.add({ id: item.id + '_subtitle2', x: item.x, y: yOffset, label: item.subtitle2, group: 'network_header_subtitle2' })
        editable && setMapping(mappings, item.id, item.id + '_subtitle2')
      }
    } else if (item.type === 'network') {
      const width = item.width
      const height = item.height
      const x = item.x
      const y = item.y

      // calc every line positions
      const cornerTop = { x, y: y - (height / 2) }
      const cornerBottom = { x, y: y + (height / 2) }
      const cornerLeft = { x: x - (width / 2), y }
      const cornerRight = { x: x + (width / 2), y }

      // push lines as nodes
      // top
      nodes.add({
        id: item.id + '_border_top',
        group: (item.color && 'network_corner_horizontal_' + item.color) || 'network_corner_horizontal_' + networkCornerColor,
        // group: 'server_linux',
        x: cornerTop.x,
        y: cornerTop.y,
        widthConstraint: { minimum: width - 2.8 }
      })
      editable && setMapping(mappings, item.id, item.id + '_border_top')
      editable && setMapping(mappings, item.id + '_config', item.id + '_border_top')
      // bottom
      nodes.add({
        id: item.id + '_border_bottom',
        group: (item.color && 'network_corner_horizontal_' + item.color) || 'network_corner_horizontal_' + networkCornerColor,
        x: cornerBottom.x,
        y: cornerBottom.y,
        widthConstraint: { minimum: width - 2.8 }
      })
      editable && setMapping(mappings, item.id, item.id + '_border_bottom')
      editable && setMapping(mappings, item.id + '_config', item.id + '_border_bottom')
      // left
      nodes.add({
        id: item.id + '_border_left',
        group: (item.color && 'network_corner_vertical_' + item.color) || 'network_corner_vertical_' + networkCornerColor,
        x: cornerLeft.x,
        y: cornerLeft.y,
        heightConstraint: { minimum: height + 1 }
      })
      editable && setMapping(mappings, item.id, item.id + '_border_left')
      editable && setMapping(mappings, item.id + '_config', item.id + '_border_left')
      // right
      nodes.add({
        id: item.id + '_border_right',
        group: (item.color && 'network_corner_vertical_' + item.color) || 'network_corner_vertical_' + networkCornerColor,
        x: cornerRight.x,
        y: cornerRight.y,
        heightConstraint: { minimum: height + 1 }
      })
      editable && setMapping(mappings, item.id, item.id + '_border_right')
      editable && setMapping(mappings, item.id + '_config', item.id + '_border_right')
      // if specified set connecting point
      if (item.position === undefined) {
        nodes.add({ id: item.id, group: 'network_edge_point_hidden', x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y - (item.height / 2) })
      } else if (item.position === 'bottom') {
        nodes.add({ id: item.id, group: 'network_edge_point' + (editable ? '_config' : ''), x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y + (item.height / 2) })
      } else if (item.position === 'top') {
        nodes.add({ id: item.id, group: 'network_edge_point' + (editable ? '_config' : ''), x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y - (item.height / 2) })
      } else if (item.position === 'left') {
        nodes.add({ id: item.id, group: 'network_edge_point' + (editable ? '_config' : ''), x: item.x - (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
      } else if (item.position === 'right') {
        nodes.add({ id: item.id, group: 'network_edge_point' + (editable ? '_config' : ''), x: item.x + (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
      } else if (item.position === 'left_right') {
        nodes.add({ id: item.id, group: 'network_edge_point_hidden', x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y - (item.height / 2) })
        // for type left_right set both left and right points with suitable suffix
        nodes.add({ id: item.id + '_left', group: 'network_edge_point' + (editable ? '_config' : ''), x: item.x - (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
        nodes.add({ id: item.id + '_right', group: 'network_edge_point' + (editable ? '_config' : ''), x: item.x + (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
        editable && setMapping(mappings, item.id, item.id + '_left')
        editable && setMapping(mappings, item.id + '_config', item.id + '_left')
        editable && setMapping(mappings, item.id, item.id + '_right')
        editable && setMapping(mappings, item.id + '_config', item.id + '_right')
      } else if (item.position === 'all') {
        nodes.add({ id: item.id, group: 'network_edge_point_hidden', x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y - (item.height / 2) })
        // for type left_right set both left and right points with suitable suffix
        nodes.add({ id: item.id + '_left', group: 'network_edge_point' + (editable ? '_config' : ''), x: item.x - (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
        nodes.add({ id: item.id + '_right', group: 'network_edge_point' + (editable ? '_config' : ''), x: item.x + (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
        editable && setMapping(mappings, item.id, item.id + '_left')
        editable && setMapping(mappings, item.id + '_config', item.id + '_left')
        editable && setMapping(mappings, item.id, item.id + '_right')
        editable && setMapping(mappings, item.id + '_config', item.id + '_right')

        // top
        nodes.add({ id: item.id + '_top', group: 'network_edge_point' + (editable ? '_config' : ''), x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y - (item.height / 2) })
        editable && setMapping(mappings, item.id, item.id + '_top')
        editable && setMapping(mappings, item.id + '_config', item.id + '_top')

        // bottom
        nodes.add({ id: item.id + '_bottom', group: 'network_edge_point' + (editable ? '_config' : ''), x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y + (item.height / 2) })
        editable && setMapping(mappings, item.id, item.id + '_bottom')
        editable && setMapping(mappings, item.id + '_config', item.id + '_bottom')
      }

      // add network config node
      editable && nodes.add({ id: item.id + '_config', group: 'network_config_point', x: item.x - (item.width / 2) + 20, y: item.y - (item.height / 2) - 25 })
      editable && setMapping(mappings, item.id, item.id + '_config')
    } else if (item.group === 'vpn') {
      // prepare offset of node position
      let currentOffset = 48
      // add node
      nodes.add({
        id: item.id,
        group: item.group,
        x: item.x,
        y: item.y,
        title: isClickableNode(item.id) ? messageTypes[language].more_info : undefined
      })
      // add label
      nodes.add({
        id: item.id + '_label',
        label: item['label_' + language] || item.label,
        group: 'node_label_gray',
        x: item.x,
        y: item.y + currentOffset
      })
      editable && setMapping(mappings, item.id, item.id + '_label')
      // add ip
      currentOffset += 18
      nodes.add({
        id: item.id + '_ip',
        label: item.ip,
        group: 'node_label_red',
        x: item.x,
        y: item.y + currentOffset,
        title: messageTypes[language].click_to_copy,
        toCopy: item.network !== undefined ? item.network + item.ip : item.ip
      })
      editable && setMapping(mappings, item.id, item.id + '_ip')

      const vm = machines && machines.find(x => x.network_name === item.id)

      if (vm) {
        prepareVmStatus(item, vm.resources.status, nodes, language)
      }
    } else if (item.type === 'scenario' && item.scenario) {
      let currentOffset = 48
      // let group = item.challenge.challenge_category?.icon

      // if (item.challenge.done) {
      //   group = group + '_done'
      // } else {
      //   group = group + '_undone'
      // }

      let label = item['label_' + language] || item.label

      if (!label && item.scenario?.name) {
        label = item.scenario.name[language]
      }

      const isNodeClickable = isClickableNode(item.id)

      // add node
      nodes.add({
        id: item.id,
        group: 'scenario',
        x: item.x,
        y: item.y,
        title: isNodeClickable ? messageTypes[language].more_info : undefined,
        chosen: !isNodeClickable ? { node: {} } : {}
      })
      // add label
      nodes.add({
        id: item.id + '_label',
        label,
        group: 'node_label_gray',
        x: item.x,
        y: item.y + currentOffset
      })
      editable && setMapping(mappings, item.id, item.id + '_label')

      if (item.sublabel || item['sublabel_' + language]) {
        currentOffset += 21
        nodes.add({
          id: item.id + '_sublabel',
          label: item['sublabel_' + language] || item.sublabel,
          group: 'node_label_gray',
          x: item.x,
          y: item.y + currentOffset
        })
        editable && setMapping(mappings, item.id, item.id + '_sublabel')
      }
    } else if (item.type === 'challenge' && item.challenge) {
      let currentOffset = 48
      let group = item.challenge.challenge_category?.icon

      if (!editable) {
        if (item.challenge.active) {
          if (item.challenge.done) {
            group = group + '_done'

            // add stars level above
            // nodes.add({
              //   id: item.id + i + '_done',
              //   group: 'check',
              //   x: item.x + 30,
              //   y: item.y + 30,
              //   // chosen: { node: {} }
              // })
          } else {
            group = group + '_undone'
          }
        } else {
          if (icons && icons[group + '_unactive']) {
            group = group + '_unactive'
          } else {
            group = 'hidden'
          }
        }
      }

      let label
      if (!item.label && !item['label_' + language] && item.challenge?.name) {
        label = item.challenge.name[language]
      } else {
        label = item['label_' + language] || item.label
      }

      if (item.overlabel || item['overlabel_' + language]) {
        // add label over node
        nodes.add({
          id: item.id + '_overlabel',
          label: item['overlabel_' + language] || item.overlabel,
          group: 'node_label_gray',
          x: item.x,
          y: item.y - 40,
          font: {
            size: settings.map_default_overlabel_size || 14
          }
        })
        editable && setMapping(mappings, item.id, item.id + '_overlabel')
      }

      const isNodeClickable = isClickableNode(item.id)

      // add node
      nodes.add({
        id: item.id,
        group,
        x: item.x,
        y: item.y,
        title: isNodeClickable ? messageTypes[language].more_info : undefined,
        chosen: !isNodeClickable ? { node: {} } : {}
      })
      // add label
      nodes.add({
        id: item.id + '_label',
        label,
        group: 'node_label_gray',
        x: item.x,
        y: item.y + currentOffset
      })
      editable && setMapping(mappings, item.id, item.id + '_label')

      if (item.sublabel || item['sublabel_' + language]) {
        currentOffset += 21
        nodes.add({
          id: item.id + '_sublabel',
          // label: item.sublabel,
          label: item['sublabel_' + language] || item.sublabel,
          group: 'node_label_gray',
          x: item.x,
          y: item.y + currentOffset
        })
        editable && setMapping(mappings, item.id, item.id + '_sublabel')
      }

      // add stars level above
      // nodes.add({
      //   id: item.id + i + '_attribute',
      //   group: 'stars' + item.challenge.level,
      //   x: item.x,
      //   y: item.y - 42,
      //   chosen: { node: {} }
      // })

      if (item.challenge.done) {
        group = group + '_done'

        // add stars level above
        nodes.add({
          id: item.id + i + '_done',
          group: 'check',
          x: item.x + 30,
          y: item.y + 30,
          // chosen: { node: {} }
        })
      }
    } else {
      // prepare offset of node position
      let currentOffset = 48
      let group = item.group

      if (group === 'hidden') {
        const hiddenFor = item.id.split('_')[1]

        // search nodes for item hiddenFor, if exists do not add this element on map
        if (nodesData.filter(node => node.id === hiddenFor).length > 0) {
          return
        }
      }

      const vm = item.type === 'vm' && machines && machines.find(x => x.machine_name === item.id)

      if (vm) {
        prepareVmStatus(item, vm.resources.status, nodes, language)
      }

      if (item.type === 'map_object' && item.flags !== undefined && item.flags.length > 0) {
        if (item.flags_done === 0) {
          group = item.group + '_' + undone
        } else if (item.flags_done > 0 && item.flags.length !== item.flags_done) {
          group = item.group + '_' + undone
        } else if (item.flags.length === item.flags_done) {
          group = item.group + '_' + done
        }
      }

      const isNodeClickable = isClickableNode(item.id)

      // add node
      nodes.add({
        id: item.id,
        group,
        x: item.x,
        y: item.y,
        title: isNodeClickable ? messageTypes[language].more_info : undefined,
        chosen: !isNodeClickable ? { node: {} } : {}
      })
      // add label
      nodes.add({
        id: item.id + '_label',
        label: item['label_' + language] || item.label,
        group: 'node_label_gray',
        x: item.x,
        y: item.y + currentOffset
      })
      editable && setMapping(mappings, item.id, item.id + '_label')

      if (item.overlabel || item['overlabel_' + language]) {
        // add label over node
        nodes.add({
          id: item.id + '_overlabel',
          label: item['overlabel_' + language] || item.overlabel,
          group: 'node_label_gray',
          x: item.x,
          y: item.y - 40,
          font: {
            size: settings.map_default_overlabel_size || 14
          }
        })
        editable && setMapping(mappings, item.id, item.id + '_overlabel')
      }

      // add sublabel if defined
      if (item.sublabel || item['sublabel_' + language]) {
        currentOffset += 17
        nodes.add({
          id: item.id + '_sublabel',
          // label: item.sublabel,
          label: item['sublabel_' + language] || item.sublabel,
          group: 'node_label_gray',
          x: item.x,
          y: item.y + currentOffset
        })
        editable && setMapping(mappings, item.id, item.id + '_sublabel')
      }
      // add dns name if defined
      if (item.dns) {
        currentOffset += 17
        nodes.add({
          id: item.id + '_dns',
          label: item.dns,
          group: 'node_label_yellow',
          x: item.x,
          y: item.y + currentOffset,
          title: messageTypes[language].click_to_copy,
          toCopy: item.domain !== undefined ? item.dns + item.domain : item.dns
        })
        editable && setMapping(mappings, item.id, item.id + '_dns')
      }
      // add ip
      if (item.ip) {
        currentOffset += 17
        nodes.add({
          id: item.id + '_ip',
          label: item.ip,
          group: item.ip_style !== undefined ? item.ip_style : 'node_label_red',
          x: item.x,
          y: item.y + currentOffset,
          title: messageTypes[language].click_to_copy,
          toCopy: item.network !== undefined ? item.network + item.ip : item.ip
        })
        editable && setMapping(mappings, item.id, item.id + '_ip')
      }
      // add public dns
      if (item.dns_public) {
        if (item.dns) currentOffset += 4
        currentOffset += 17
        nodes.add({
          id: item.id + '_dns_public',
          label: item.dns_public,
          group: 'node_label_green',
          x: item.x,
          y: item.y + currentOffset,
          title: messageTypes[language].click_to_copy,
          toCopy: item.domain !== undefined ? item.dns_public + item.domain : item.dns_public
        })
        editable && setMapping(mappings, item.id, item.id + '_dns_public')
      }
      // add public ip
      if (item.ip_public) {
        currentOffset += 17
        nodes.add({
          id: item.id + '_ip_public',
          label: item.ip_public,
          group: item.ip_style !== undefined ? item.ip_style : 'node_label_red',
          x: item.x,
          y: item.y + currentOffset,
          title: messageTypes[language].click_to_copy,
          toCopy: item.network_public !== undefined ? item.network_public + item.ip_public : item.ip_public
        })
        editable && setMapping(mappings, item.id, item.id + '_ip_public')
      }
      // add ip2 name if defined
      if (item.ip2) {
        currentOffset += 17
        nodes.add({
          id: item.id + '_ip2',
          label: item.ip2,
          group: item.ip2_style !== undefined ? item.ip2_style : 'node_label_gray',
          x: item.x,
          y: item.y + currentOffset,
          title: messageTypes[language].click_to_copy,
          toCopy: item.network2 !== undefined ? item.network2 + item.ip2 : item.ip2
        })
        editable && setMapping(mappings, item.id, item.id + '_ip2')
      }
    }

    // draw element attributes
    if (item.attributes && item.type !== 'challenge') {
      item.attributes.forEach((attr, i) => {
        let x
        let y

        if (attr.position === 'top') {
          x = item.x
          y = item.y - 42
        } else if (attr.position === 'top_right') {
          x = item.x + 40 + (attr.x_offset || 0)
          y = item.y - 25
        } else if (attr.position === 'bottom_right') {
          x = item.x + 40 + (attr.x_offset || 0)
          y = item.y + 25
        }

        nodes.add({
          id: item.id + i + '_attribute',
          group: attr.group,
          x,
          y,
          chosen: { node: {} }
        })
      })
    }
  })

  return { nodes, mappings }
}

export const prepareEdges = function (nodesData, edgesData) {
  const edges = new DataSet([])

  edgesData.forEach((item, i) => {
    if (item._deleted) return

    if (item.group === 'curve_arrow') {
      // if edge is type of curve, set special edge
      edges.add({
        id: item.id,
        from: item.from,
        to: item.to,
        smooth: { enabled: true, type: 'curvedCW', roundness: item.roundness ? item.roundness : 0.1 },
        arrows: { to: { enabled: true } },
        width: item.edge_width,
        color: {
          color: item.color || edgeColor,
          highlight: item.color || edgeColor,
          hover: item.color || edgeColor
        }
      })
    } else if (item.group === 'curve_dashed') {
      // if edge is type of curve, set special edge
      edges.add({
        id: item.id,
        from: item.from,
        to: item.to,
        smooth: { enabled: true, type: 'curvedCW', roundness: item.roundness ? item.roundness : 0.1 },
        dashes: true,
        width: item.edge_width,
        color: {
          color: item.color || edgeColor,
          highlight: item.color || edgeColor,
          hover: item.color || edgeColor
        }
      })
    } else if (item.group === 'curve_dotted') {
      // if edge is type of curve, set special edge
      edges.add({
        id: item.id,
        from: item.from,
        to: item.to,
        smooth: { enabled: true, type: 'curvedCW', roundness: item.roundness ? item.roundness : 0.1 },
        dashes: [0.1, 3],
        width: item.edge_width,
        color: {
          color: item.color || edgeColor,
          highlight: item.color || edgeColor,
          hover: item.color || edgeColor
        }
      })
    } else if (item.group === 'dashed') {
      // if edge is type of curve, set special edge
      edges.add({
        id: item.id,
        from: item.from,
        to: item.to,
        dashes: true,
        width: item.edge_width,
        color: {
          color: item.color || edgeColor,
          highlight: item.color || edgeColor,
          hover: item.color || edgeColor
        }
      })
    } else if (item.group === 'dotted') {
      // if edge is type of curve, set special edge
      edges.add({
        id: item.id,
        from: item.from,
        to: item.to,
        dashes: [0.1, 3],
        width: item.edge_width,
        color: {
          color: item.color || edgeColor,
          highlight: item.color || edgeColor,
          hover: item.color || edgeColor
        }
      })
    } else {
      // check if connected elements are type of left_right to properly set edge point
      const checkFrom = nodesData.filter(obj => {
        return obj.id === item.from && obj.position === 'left_right'
      })

      const checkTo = nodesData.filter(obj => {
        return obj.id === item.to && obj.position === 'left_right'
      })

      // for default from and to are without left/right modification
      let nodeFrom = item.from
      let nodeTo = item.to

      if (checkFrom.length > 0) {
        nodeFrom += '_right'
      }

      if (checkTo.length > 0) {
        nodeTo += '_left'
      }

      // otherwise simple add edge
      edges.add({
        id: item.id,
        from: nodeFrom,
        to: nodeTo,
        width: item.edge_width,
        color: {
          color: item.color || edgeColor,
          highlight: item.color || edgeColor,
          hover: item.color || edgeColor
        }
      })
    }
  })

  return edges
}
