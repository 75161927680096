import { Component } from 'react'
import { connect } from 'react-redux'
import { messageTypes } from '../../../actions/messages'
import { signinOpenid } from '../../../actions/'
import { openNotificationPopup, setCookie, getUrlParameter, getCookie } from '../../../actions/helpers'
import { setAuth, setUser, setStack, setUserStacks } from '../../../store/actions'

import config, { views } from '../../../config/'

class MoodleOAuth extends Component {
  componentDidMount () {
    const { setAuth, setUser, setStack, setUserStacks } = this.props
    const idToken = getUrlParameter('id_token', true)
    const accessToken = getUrlParameter('access_token', true)
    const auth = !!getCookie('_token')
    const language = window.localStorage.getItem('language') || config.default_lang

    // const id_token = window.location.href.split('oauth#id_token=')[1]

    if (auth) {
      this.props.history.push('/')
    } else {
      signinOpenid({ idToken, accessToken }, '').then(json => {
        if (json.status === 'ok' || json.status === 'warn') {
          const { token, name, email, expires, view, stack, stacks, team_token: teamToken } = json.auth
          setCookie('_token', token, expires)
          const userData = { name, email, team_token: teamToken }
          // user info save to localStorage
          window.localStorage.setItem('user', JSON.stringify(userData))

          if (view && view.length === 32) {
            setAuth(view)
            setCookie('_view', view)
          } else { setAuth(true) }

          setUser(userData)
          setUserStacks(stacks)
          setStack(stack)

          openNotificationPopup(messageTypes[language].success, json.response[language], (json.status === 'warn' && 'frown') || 'smile', undefined, undefined, (json.status === 'warn' && 15) || 2.5)

          if (view === views.ur) {
            this.props.history.push('/ranking')
          } else {
            this.props.history.push('/')
          }
        } else if (json.status === 'err') {
          this.props.history.push('/')
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown', undefined, undefined, 15)
        }
      })
    }
  }

  render () {
    return null
  }
}

const mapStateToProps = state => ({
  auth: state.hdStore.auth,
  language: state.hdStore.language
})

const mapDispatchToProps = {
  setAuth,
  setUser,
  setStack,
  setUserStacks
}

const MoodleOAuthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoodleOAuth)

export default MoodleOAuthContainer
