import React, { Component } from 'react'
import { Layout, Menu, Drawer, Button, Tag, Typography, Affix, Input } from 'antd'
import { SettingOutlined, MenuOutlined, LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { pathNames, settingPathNames, messageTypes } from '../../actions/messages'
import { renderIcon } from '../../actions/helpers'
import config, { views } from '../../config/'
import CtfNavbarClock from '../../components/common/ctfNavbarClock'
import MainMenux from './menu'
import AddRecordButton from './addRecordButton'

const { Text } = Typography;
const { Sider } = Layout
const SubMenu = Menu.SubMenu

class Navbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collapsed: false,
      screenWidth: null,
      settingsOpened: false,
      filteredStacks: [],
      stacksSearch: undefined
    }
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', () => { this.updateWindowDimensions() })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.filteredStacks?.length === 0 && this.props.stacks?.length > 0 && !this.state.stacksSearch) {
      this.setState({ filteredStacks: this.props.stacks })
    }

    if(this.state.stacksSearch !== prevState.stacksSearch) {
      const re = new RegExp(this.state.stacksSearch, "gi");
      this.setState({ filteredStacks: this.props.stacks.filter(x => ((typeof x.name === 'object' && x.name[this.props.language]) || x.name).match(re)) })
    }
  }

  updateWindowDimensions () {
    if (window.innerWidth < 768) {
      this.handleCollapse(true)
    } else {
      this.handleCollapse(false)
    }
    // this.setState({ screenWidth: window.innerWidth });
  }

  handleCollapse = (collapsed) => {
    this.setState({ collapsed })

    if (this.state.settingsOpened) {
      this.setState({ openedBeforeCollapse: true })
    } else if (this.state.openedBeforeCollapse) {
      this.setState({ settingsOpened: true, openedBeforeCollapse: false })
    }
  }

  setSelected () {
    const path = window.location.pathname
    let returnPath = '0'
    pathNames.forEach((val, i) => {
      if (path === val.path) {
        returnPath = i.toString()
      }
    })

    if (path === '/settings') {
      returnPath = '6'
    } else if (path === '/settings/users') {
      returnPath = '7'
    } else if (path === '/settings/scenarios') {
      returnPath = '8'
    } else if (path === '/settings/machines') {
      returnPath = '9'
    } else if (path === '/settings/quiz') {
      returnPath = '10'
    }
    return returnPath
  }

  showDrawer = (open) => {
    this.setState({ open })
  }

  showDrawer2 = (open2) => {
    this.setState({ open2 })
  }

  render () {
    const { language, auth, stack, stacks } = this.props
    const { filteredStacks } = this.state
    // if (!language) {
    //   return (<div />)
    // }
    const roleSuperAdmin = (auth === views.sa)
    const roleAdmin = (auth === views.ad)
    const roleUser = (auth === views.us || auth === views.ucr)
    const roleUserRankingOnly = (auth === views.ur)

    const currentStackId = stack?._id
    const switchStack = undefined

// console.log('this.state.open', this.props.stacks)

    // const aff = () => { this.props.switchStack(stack); this.showDrawer(false) }
    const newMenu = true

    console.log('EEE', stacks)
    return (
      <>
      {newMenu && <Drawer
        title={<Button onClick={() => this.showDrawer(false)} className='switch-stack-show-drawer'>← Zwiń</Button>}
        placement='left'
        closable={false}
        className='select-stack-drawer'
        onClose={() => this.showDrawer(false)}
        visible={this.state.open}
        width={330}
        // visible={true}
        key='select-stack'
        footer={
          <img alt='Logo light' src={'/images/' + ((config.app_logo_menu && 'customization/' + config.app_logo_menu_drawer) || 'logo_light.png')} />
        }
      >
          {stacks?.length > 10 && <Input onChange={(value) => this.setState({ stacksSearch: value.target.value })} allowClear placeholder={messageTypes[language].filter_stacks} className='stacks-search' />}
          {stacks === undefined && <p className='stacks-loader'><LoadingOutlined /></p>}
          {this.state.stacksSearch && filteredStacks?.length === 0 && <p className='stacks-search-no-results'>{messageTypes[language].no_results}</p>}
          {
            filteredStacks && filteredStacks.length > 0 && filteredStacks.map((stack, n) =>
              (
                <p
                  key={n}
                  onClick={() => (!stack.demo || stack.demo === 'partial') && stack._id !== currentStackId && this.props.switchStack(stack, () => this.showDrawer(false))}
                  className={
                    (stack.demo === 'full' && 'stack-unavailable') ||
                    (stack._id === currentStackId && 'stack-current')
                  }
                >
                  <span className='stack-name'>{(typeof stack.name === 'object' && stack.name[language]) || stack.name}
                    {
                      (
                        (stack.demo === 'full' && ' (' + messageTypes[language].stack_unavailable + ')') ||
                        (stack._id === currentStackId && ' (' + messageTypes[language].current + ')')
                      )
                    }
                  </span>
                  {/*stack.status && (
                    <Tag className={'ant-tag-red' + (stack.status === 'poweredon' ? ' status-on' : '')}>
                      {messageTypes[language]['stack_status_' + stack.status]}
                    </Tag>)*/}
                </p>)
            )
          }
      </Drawer>}

      {newMenu && <Drawer
        title={<Button onClick={() => this.showDrawer2(false)} className='switch-stack-show-drawer'>← Zwiń</Button>}
        placement='left'
        closable={false}
        onClose={() => this.showDrawer2(false)}
        mask
        className='select-stack-drawer drawer-menu-main'
        // onClose={() => this.showDrawer(false)}
        visible={this.state.open2}
        width={330}
        // visible={true}
        key='select-stack'
        footer={
          <a href="https://pja.edu.pl/" target="_blank" noreferrer><img alt='Logo light' src={'/images/' + ((config.app_logo_menu && 'customization/' + config.app_logo_menu_drawer) || 'logo_light.png')} /></a>
        }
      >
        <MainMenux language={language} auth={auth} stack={stack} mode='inline' afterMenuClick={() => this.showDrawer2(false)} />
      </Drawer>}

      <Sider
        // collapsible
        // collapsed={this.state.collapsed}
        collapsed={newMenu}
        onCollapse={this.handleCollapse}
        // trigger={<div className='bottom-logo'><img alt='Logo light' src={'/images/' + ((config.app_logo_menu && 'customization/' + config.app_logo_menu) || 'logo_light.png')} /></div>}
        >
          <div style={{ height: '105px', borderBottom: '1px solid #fff', alignItems: 'center', display: 'flex', textAlign: 'center' }} className='menu-header-button'>
            <div>
              <Button type="default" onClick={() => this.showDrawer(true)}>
                {/*<MenuOutlined />*/}
                <p className='line1' />
                <p className='line2' />
                <p className='line3' />
                <p>Menu</p>
              </Button>
            </div>
          </div>

          {stack && stack.is_ctf && <CtfNavbarClock className={'ctf-clock-navbar' + (this.state.settingsOpened || this.state.collapsed ? ' d-none' : '')} />}
          {!newMenu && <MainMenux language={language} auth={auth} stack={stack} />}

          {newMenu && <p onClick={() => this.showDrawer(true)} className='select-stack-title'>
            <Text
              ellipsis
              style={{ maxHeight: '68vh' }}
            >
              Bieżący kurs: {(typeof this.props.stack?.name === 'object' && this.props.stack?.name[language]) || this.props.stack?.name}
            </Text>
          </p>}
          <div className='bottom-logo-container'>
            <div className='bottom-logo'><a href="https://pja.edu.pl/" target="_blank" noreferrer><img alt='Logo light' src={'/images/' + ((config.app_logo_menu && 'customization/' + config.app_logo_menu) || 'logo_light.png')} /></a></div>
          </div>
        </Sider>
      </>
    )
  }
}

export default Navbar
